import {nextTick, onMounted, onUnmounted, reactive} from 'vue';
import msg from "@/utils/msg";
import CommonService, {isMobile} from "@/services/CommonService";
import {onKeyStroke} from "@vueuse/core";


const state = reactive({
    notification: {
        message: '',
        level: '', // INFO, ERROR
        show: false,
    },
    desc: {
        content: "",
        visible: false,
        style: {
            left: "0px",
            top: "0px",
        },
    },
    message: {
        content: "",
        clazz: "",
        show: false,
    },
    dark: false,
    preventEsc: false,
    clickFn: Function,
    loading: false,
    showSubMenu: false,
    bottomLine: 0,
})

export default {
    state,

    /********************************************** **/
    /**  NOTIFICATION                               **/
    /********************************************** **/
    error(message) {
        this.notification(message, 'ERROR', 5000)
    },

    tooltip(message) {
        if (!message) {
            this.state.notification.show = false
            return
        }

        this.notification(message, 'HOVER', 10000)
    },

    info(message) {
        this.notification(message, 'INFO', 2500)
    },

    notification(message, level, timeout) {
        this.state.notification.message = message
        this.state.notification.level = level
        this.state.notification.show = true

        setTimeout(function() {
            this.state.notification.show = false
        }.bind(this), timeout);
    },


    /********************************************** **/
    /**  MESSAGE                                    **/
    /********************************************** **/
    msg: {
        error(content) {
            this.showMessage(content, 'error', 5000)
        },

        info(content) {
            this.showMessage(content, 'info', 1000)
        },

        errorCode(content) {
            this.showMessage(msg.get(content), 'error', 5000)
        },

        infoCode(content) {
            this.showMessage(msg.get(content), 'info', 2500)
        },

        showMessage(content, clazz, timeout) {
            state.message.show = true
            state.message.content = content
            state.message.clazz = clazz

            setTimeout(function () {
                state.message.show = false
            }.bind(this), timeout);
        },
    },

    /********************************************** **/
    /**  DESCRIPTION                                **/
    /********************************************** **/
    showDesc(mouseOverRef, text) {
        const {height} = window.innerHeight;

        this.state.desc.content = text
        this.state.desc.visible = true

        const desc = document.getElementById("description");

        nextTick(() => {
            let x = mouseOverRef.getBoundingClientRect().left - desc.clientWidth
            let y = mouseOverRef.getBoundingClientRect().top + desc.clientHeight > height
                ? mouseOverRef.getBoundingClientRect().top - desc.clientHeight
                : mouseOverRef.getBoundingClientRect().top

            if (x < 0) {
                x = 0
            }

            this.state.desc.style.left = (x + 8) + "px"
            this.state.desc.style.top = (y + 20) + "px"
        })
    },

    hideDesc() {
        this.state.desc.visible = false
    },

    /********************************************** **/
    /**  CONTEXT MENU                               **/
    /********************************************** **/
    calcContextMenuStyle(event, contextMenu, windowSize) {
        // console.log("windowSize, event, contextMenuWidth");
        // console.log(windowSize);
        // console.log(event);
        // console.log(contextMenu);
        if(contextMenu === undefined || contextMenu === null) return

        let x = windowSize.width.value < event.clientX + contextMenu.clientWidth
            ? event.clientX - contextMenu.clientWidth
            : event.clientX

        let y = windowSize.height.value < event.clientY + contextMenu.clientHeight
            ? event.clientY - contextMenu.clientHeight
            : event.clientY

        return {
            left: (x) + "px",
            top: (y) + "px",
        }
    },

    /********************************************** **/
    /**  DARK                                       **/
    /********************************************** **/
    dark: {
        show(preventEsc = false) {
            state.dark = true
            state.preventEsc = preventEsc
            document.getElementById('app').style.overflowY = 'hidden';
        },
        hide() {
            if (state.clickFn)
                state.clickFn()

            if (!state.preventEsc) {
                state.dark = false
                document.getElementById('app').style.overflowY = 'auto';
            }
        },
        preventEsc(trueFalse = true) {
            state.preventEsc = trueFalse
        },
        isShown() {
            return state.dark
        },
    },


    /********************************************** **/
    /**  TEXTAREA                                   **/
    /********************************************** **/

    /**
     * Do textarea přidej:
     * id="someId"
     * @input="adjustTextarea('someId')"
     *
     * a pak do komponenty:
     * watchEffect(() => {
     *   if (!ministry) return
     *   adjustTextarea();
     * })
     */
    adjustTextarea(textareaId) {
        CommonService.tryMultiple(() => {
            const textarea = document.getElementById(textareaId)
            textarea.style.height = textarea.scrollHeight + 'px';
        }, 100, 10)
    },


    /********************************************** **/
    /**  MODAL                                      **/
    /********************************************** **/
    getModalStyle() {
        return isMobile && `bottom:${state.bottomLine + 155}px`
    },

    customBackspace(fnAfter) {
        onKeyStroke('Backspace', (e) => {
            if (e.target.localName !== "body") return

            e.preventDefault()
            e.stopPropagation()
            fnAfter()
        }, { target: document })
    }
}